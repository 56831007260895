import React, { useEffect } from 'react';

const RedirectComponent = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return (
    <div className='w-screen h-screen flex flex-col text-center justify-center font-bold'>
      <p className=''>Redirecting...</p>
    </div>
  );
};

export default RedirectComponent;