import * as React from "react";
import './Disclosures.css';
import logoSVG from "../../assets/logosvg.svg"
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import BTA1 from "../../assets/DisclosuresAssets/ExBTA1.png"
import BTA2 from "../../assets/DisclosuresAssets/ExBTA2.png"
import BTA3 from "../../assets/DisclosuresAssets/ExBTA3.png"

import CN1 from "../../assets/DisclosuresAssets/CN1.png"
import CN2 from "../../assets/DisclosuresAssets/CN2.png"

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { motion } from "framer-motion";

function ExclusiveBTAComponent() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-eggshell mb-10"
        >
            <div className="disclosures-wrap">
                <div className="disclanding-wrap">
                    <div className="disclosures-landing">
                        <div className="disc-text">
                            {/* Adinqra's Disclosures */}
                            <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                            <p className="font-bold">BTA Exclusive Agreement</p>
                        </div>
                        <hr className="extendbar"></hr>
                    </div>

                    <Carousel
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={7}
                    >
                        <div>
                            <img src={BTA1} alt='bta1' />
                        </div>
                        <div>
                            <img src={BTA2} alt='bta2' />
                        </div>
                        <div>
                            <img src={BTA3} alt='bta3' />
                        </div>
                    </Carousel>


                    <div className="disclosures-landing">
                        <div className="disc-text">
                            {/* Adinqra's Disclosures */}
                            <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                            <p className="font-bold">Consumer Notice</p>
                        </div>
                        <hr className="extendbar"></hr>
                    </div>

                    <Carousel
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={7}
                    >
                        <div>
                            <img src={CN1} alt="cn1" />
                            {/* <p className="legend">Page 1</p> */}
                        </div>
                        <div>
                            <img src={CN2} alt="cn2" />
                        </div>
                    </Carousel>
                </div>
            </div>
        </motion.div>
    )
}

export default ExclusiveBTAComponent
