import * as React from "react";
import './Disclosures.css';
import logoSVG from "../../assets/logosvg.svg"
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import {motion} from "framer-motion";

import {useNavigate } from 'react-router-dom';

// const DISC_LINK_REDIRECT = "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=82bc48fd-b01d-4711-945c-8bc39016f753&env=na4&acct=be89b9c4-f04a-4a69-a734-ea3ba53c37db&v=2";
// const EXCLUSIVE_LINK_REDIRECT = "";
// const NON_EXCLUSIVE_LINK_REDIRECT = "";

function Disclosures(props) {
    const navigate = useNavigate();

    // const openDiscLink = () => {
    //     window.open(DISC_LINK_REDIRECT);
    // }

    const redirectExclusive = () => {
        navigate("/exagreement")
    }

    const redirectNonExclusive = () => {
        navigate("/nonexagreement")
    }

    return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className=""
            >
                <div className='bg-eggshell w-screen h-screen flex flex-col text-center justify-center font-bold'>
                {/* <img className="" src={logoSVG} alt="adinqra-logo" /> */}

                {/* <div className="disclosures-landing">
                    <div className="disc-text">
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">Consumer Notice</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={CN1} alt="cn1" />
                    </div>
                    <div>
                        <img src={CN2} alt="cn2"/>
                    </div>
                </Carousel> */}

                {/* <button class="bg-gray-300 mx-auto w-min hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}


                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}
                
                {/* <hr className="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" /> */}

                {/* <div className="disclosures-landing">
                    <div className="disc-text">
                        <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />
                        <p className="font-bold">BTA</p>
                    </div>
                    <hr className="extendbar"></hr>
                </div>

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={7}
                >
                    <div>
                        <img src={BTA1} alt='bta1' />
                    </div>
                    <div>
                        <img src={BTA2} alt="bta2" />
                    </div>
                    <div>
                        <img src={BTA3} alt="bta3" />
                    </div>
                </Carousel> */}

                {/* <button class="bg-red-300 mx-auto w-min hover:bg-red-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Download</span>
                </button> */}


                {/* </div> */}

                {/* <hr className="w-full mb-4 mt-4 h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" /> */}


                {/* <div className="comingsoon">Coming soon</div> */}
                {/* <div className="disc-section">

                    <button className="sign-btn" onClick={openDiscLink}>
                        Sign
                    </button>
                </div> */}

                <div className="w-full flex flex-row flex-wrap justify-center gap-3 px-2 py-12 rounded">
                    <img className="disc-logo" src={logoSVG} alt="adinqra-logo" />

                    <button className="redirect-btn w-auto" onClick={redirectExclusive}>
                        View Exclusive
                    </button>


                    <button className="redirect-btn w-auto" onClick={redirectNonExclusive}>
                        View Non-Exclusive
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default Disclosures;