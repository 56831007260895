import './App.css';
import { useEffect } from 'react';
import Home from './Components/Home/Home'
import Form from './Components/Form/Form';
import Disclosures from './Components/Disclosures/Disclosures';
import TermsOfService from './Components/TOS/TermsOfService';
import Navigation from './Components/Navigation/Navigation';
import Footer from "./Components/Footer/Footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Feedback from './Components/Feedback/Feedback';
import ThankYou from './Components/ThankYou/ThankYou';
import AboutUs from './Components/AboutUs/AboutUs';
import RedirectComponent from './Components/Redirects/RedirectComponent';
import ExclusiveBTAComponent from './Components/Disclosures/ExclusiveBTAComponent';
import NonExclusiveBTAComponent from './Components/Disclosures/NonExclusiveBTAComponent';

const NON_EXCLUSIVE_LINK_REDIRECT = "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d40bab86-3ec3-4246-9cb8-60fa014ec5ab&env=na4&acct=be89b9c4-f04a-4a69-a734-ea3ba53c37db&v=2";
const EXCLUSIVE_LINK_REDIRECT = "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=82bc48fd-b01d-4711-945c-8bc39016f753&env=na4&acct=be89b9c4-f04a-4a69-a734-ea3ba53c37db&v=2";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App bg-eggshell">
      <Router basename="">
        <Navigation />
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route path="/*" element={<Navigate from="/home" to="home" />
          }>
          </Route>

          <Route path="home" element={<Home />}>

          </Route>

          <Route path="form" element={<Form />}>

          </Route>

          <Route path="about" element={<AboutUs />}>

          </Route>

          <Route path="feedback" element={<Feedback />}>

          </Route>

          <Route path="thankyou" element={<ThankYou />}>

          </Route>

          <Route path="disclosures" element={<Disclosures />}>

          </Route>

          <Route path="tos" element={<TermsOfService />}>

          </Route>

          <Route path="exagreement" element={<ExclusiveBTAComponent/>} >

          </Route>

          <Route path="nonexagreement" element={<NonExclusiveBTAComponent/>} >

          </Route>

          <Route path="exclusive" element={<RedirectComponent url={EXCLUSIVE_LINK_REDIRECT}/>} >

          </Route>

          <Route path="nonexclusive" element={<RedirectComponent url={NON_EXCLUSIVE_LINK_REDIRECT}/>} >

          </Route>

        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
