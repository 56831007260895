import * as React from "react";
import { useState } from 'react';
import './Form.css';
import logoSVG from "../../assets/logosvg.svg"
import { /*useLocation,*/ useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
// import Feedback from "../Feedback/Feedback";

// function encode(data) {
//     return Object.keys(data)
//         .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//         .join('&');
// }

function ApplicantSection(props) {
    return (
        <div className="form-section">
            <div className="section-header mb-2">
                <div className="text-lg font-bold mb-3">Applicant #{props.numberId}</div>
            </div>

            <div className="group-fields">
                <div className="horizontal-input">
                    <label
                        htmlFor={"firstname" + props.numberId}
                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                    >
                        <span className="text-xs font-medium text-gray-700">First</span>

                        <input
                            required
                            type="text"
                            id={"firstname" + props.numberId}
                            name={"firstname" + props.numberId}
                            placeholder="John"
                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />
                    </label>

                    <label
                        htmlFor={"lastname" + props.numberId}
                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                    >
                        <span className="text-xs font-medium text-gray-700">Last</span>

                        <input
                            required
                            type="text"
                            id={"lastname" + props.numberId}
                            name={"lastname" + props.numberId}
                            placeholder="Doe"
                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />
                    </label>
                </div>

                <div className="input-field">
                    {/* <label for={"phone" + props.numberId} className="block mb-1 text-sm font-bold text-gray-900">Phone number</label>
                    <input required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        type="phone" id={"phone" + props.numberId} name={"phone" + props.numberId}
                        placeholder="Phone number" /> */}


                    <label
                        htmlFor={"phone" + props.numberId}
                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                    >
                        <span className="text-xs font-medium text-gray-700">Phone</span>

                        <input
                            required
                            type="text"
                            id={"phone" + props.numberId}
                            name={"phone" + props.numberId}
                            placeholder="Phone"
                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />
                    </label>
                </div>

                <div className="input-field">
                    {/* <label for={"email" + props.numberId} className="block mb-1 text-sm font-bold text-gray-900">Email address</label>
                    <input required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        type="email" id={"email" + props.numberId} name={"email" + props.numberId}
                        placeholder="Email" /> */}


                    <label
                        htmlFor={"email" + props.numberId}
                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                    >
                        <span className="text-xs font-medium text-gray-700">Email</span>

                        <input
                            required
                            type="email"
                            id={"email" + props.numberId}
                            name={"email" + props.numberId}
                            placeholder="Email"
                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />
                    </label>
                </div>
            </div>

            <br />

            <div className="section-header">
                <b>Verification and Credit</b>
            </div>

            <div className="horizontal-input">
                <label htmlFor="move-in-date">Intended move-in date:</label>
                <input required type="date"
                    id={"move-in-date" + props.numberId}
                    name={"move-in-date" + + props.numberId} />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="monthly-income">Total Monthly Income:</label>
                <input required className="justify-text-right" type="number"
                    id={"monthly-income" + props.numberId}
                    name={"monthly-income" + props.numberId} placeholder="$$$" min="0" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="employment-length">Length of employment(months):</label>
                <input required className="justify-text-right"
                    type="number" id={"employment-length" + props.numberId}
                    name={"employment-length" + props.numberId}
                    placeholder="0" min="0" step="1" />
            </div>

            <br />

            <div className="section-header">
                <b>Applicant Credit Score</b>
            </div>

            <div className="horizontal-input">
                <label htmlFor="credit-score">Estimated Credit Score:</label>
                <input required className="justify-text-right"
                    type="number"
                    id={"credit-score" + props.numberId}
                    name={"credit-score" + props.numberId}
                    placeholder="400+" min="0" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="prior-evictions">Prior Evictions?</label>
                <select required
                    name={"prior-evictions" + props.numberId}
                    id={"prior-evictions" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="open-collections">Open Collections?</label>
                <select
                    required
                    name={"open-collections" + props.numberId}
                    id={"open-collections" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="current-rent">Current Rent Payment:</label>
                <input required className="justify-text-right" type="number"
                    id={"current-rent" + props.numberId}
                    name={"current-rent" + props.numberId}
                    placeholder="$$$" min="0" />
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="deposit-ready">Are you deposit ready?</label>
                <select required
                    name={"deposit-ready" + props.numberId}
                    id={"deposit-ready" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                    <option value="Will be by move date">Will be by move date</option>
                </select>
            </div>

            <br />

            <div className="horizontal-input">
                <label htmlFor="applied-elsewhere">Have you recently applied elsewhere?</label>
                <select required
                    name={"applied-elsewhere" + props.numberId}
                    id={"applied-elsewhere" + props.numberId}>
                    <option></option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>
        </div>
    );
}

function Form(props) {
    const navigate = useNavigate();
    // const location = useLocation();
    // const [state, setState] = useState({})
    const [numOfApplicants, setNumOfApplicants] = useState(1);
    const [toggleCosign, setToggleCosign] = useState(false);
    const [secondCosigner, setSecondCosign] = useState(false);

    const [togglePets, setTogglePets] = useState(false);
    const [hasCat, setHasCat] = useState(false);
    const [hasDog, setHasDog] = useState(false);
    const [hasOther, setHasOther] = useState(false);
    const [toggleAgree, setToggleAgree] = useState(false);


    const handleNumOfApplicants = (e) => {
        setNumOfApplicants(e.target.value);
    }

    const handleToggleCosign = (e) => {
        setToggleCosign(!toggleCosign);
    }

    const handleSecondCosign = (e) => {
        setSecondCosign(!secondCosigner);
    }

    const handleTogglePets = (e) => {
        setTogglePets(!togglePets);
    }

    const handleHasCat = (e) => {
        setHasCat(!hasCat);
    }

    const handleHasDog = (e) => {
        setHasDog(!hasDog);
    }

    const handleHasOther = (e) => {
        setHasOther(!hasOther);
    }

    const handleToggleAgree = (e) => {
        setToggleAgree(!toggleAgree);
    }

    const handleHomeClick = (e) => {
        navigate("/home");
    }

    const handleTOSClick = (e) => {
        var win = window.open("/tos", '_blank');
        win.focus();
        // navigate("/tos");
    }


    // const handleChange = (e) => {
    //     setState({ ...state, [e.target.name]: e.target.value })
    // }

    function generateApplicantForms() {
        let forms = [];

        for (let i = 1; i <= numOfApplicants; i++) {
            forms.push(<>
                <ApplicantSection numberId={i} key={i} />
                <hr className="w-full h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />
            </>
            );
        }

        return forms;
    }

    const handleSubmit = event => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/form", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
            .then(() => console.log("Form successfully submitted"))
            .catch(error => alert(error));

        navigate('/feedback')
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="form-wrap">
            <form
                name="adinqra-questionnaire"
                id="adinqra-questionnaire"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="form-content"
                action="/feedback"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="adinqra-questionnaire" />


                <div className="form-header">
                    <img className="adin-logo" src={logoSVG} alt="adinqra-logo" onClick={handleHomeClick} />
                </div>

                {/* Number of applicants */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Total applicants</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="num-applicants">Number of Applicants:</label>
                        <input required className="justify-text-left"
                            type="number" id="num-applicants"
                            name="num-applicants" min={1} step={1} max={8}
                            value={numOfApplicants}
                            onChange={handleNumOfApplicants}
                        />
                    </div>
                    <div id="applicant-details"></div>
                </div>

                {/* General Section */}
                {/* {Array(numOfApplicants).map((x, index) => <ApplicantSection numberId={x} key={index} />)} */}
                {generateApplicantForms()}
                {/* {Array(numOfApplicants).map(function (x) {
                    return (<ApplicantSection numberId={x} key={x} />)
                }
                )} */}

                {/* Cosigner Section */}
                <div className="form-section">
                    <div className="section-header text-lg mb-3">
                        <b>Cosigner Information</b>
                    </div>

                    <div className="checkbox-wrap">
                        <label htmlFor="cosigner" className="mb-2">Applying with a cosigner?</label>
                        <input className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                            type="checkbox" id="cosigner" name="cosigner" checked={toggleCosign} value={toggleCosign} onChange={handleToggleCosign} />
                    </div>

                    {toggleCosign ? (
                        <>
                            {/* <div className="group-fields">
                                <div className="input-field">
                                    <label for="cosigner-firstname" className="block mb-1 text-sm font-bold text-gray-900">First name</label>
                                    <input required
                                        type="text" id="cosigner-firstname" name="cosigner-firstname"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Cosigner's first name" />
                                </div>

                                <div className="input-field">
                                    <label for="cosigner-lastname" className="block mb-1 text-sm font-bold text-gray-900">Last name</label>
                                    <input required
                                        type="text" id="cosigner-lastname" name="cosigner-lastname"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Cosigner's last name" />
                                </div>

                                <div className="input-field">
                                    <label for="cosigner-phone" className="block mb-1 text-sm font-bold text-gray-900">Cosigner phone</label>
                                    <input required
                                        type="text" id="cosigner-phone" name="cosigner-phone"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Cosigner's phone number" />
                                </div>

                                <div className="input-field">
                                    <label for="cosigner-email" className="block mb-1 text-sm font-bold text-gray-900">Cosigner email</label>
                                    <input required
                                        type="email" id="cosigner-email" name="cosigner-email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Cosigner's email" />
                                </div>
                            </div> */}

                            <div className="group-fields">
                                <div className="horizontal-input">
                                    <label
                                        htmlFor="cosigner-firstname"
                                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                    >
                                        <span className="text-xs font-medium text-gray-700">First</span>

                                        <input
                                            required
                                            type="text"
                                            id="cosigner-firstname"
                                            name="cosigner-firstname"
                                            placeholder="John"
                                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </label>

                                    <label
                                        htmlFor="cosigner-lastname"
                                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                    >
                                        <span className="text-xs font-medium text-gray-700">Last</span>

                                        <input
                                            required
                                            type="text"
                                            id="cosigner-lastname"
                                            name="cosigner-lastname"
                                            placeholder="Doe"
                                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </label>
                                </div>

                                <div className="input-field">
                                    <label
                                        htmlFor="cosigner-phone"
                                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                    >
                                        <span className="text-xs font-medium text-gray-700">Phone</span>

                                        <input
                                            required
                                            type="text"
                                            id="cosigner-phone"
                                            name="cosigner-phone"
                                            placeholder="Phone"
                                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </label>
                                </div>

                                <div className="input-field">
                                    <label
                                        htmlFor="cosigner-email"
                                        className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                    >
                                        <span className="text-xs font-medium text-gray-700">Email</span>

                                        <input
                                            required
                                            type="email"
                                            id="cosigner-email"
                                            name="cosigner-email"
                                            placeholder="Email"
                                            className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </label>
                                </div>
                            </div>

                            <br />

                            <div className="checkbox-wrap">
                                <label htmlFor="cosigner" className="mb-2">Have a second cosigner?</label>
                                <input className="" type="checkbox" id="secondcosigner" name="secondcosigner" checked={secondCosigner} value={secondCosigner} onChange={handleSecondCosign} />
                            </div>

                            {secondCosigner ? (
                                <div className="group-fields">
                                    <div className="horizontal-input">
                                        <label
                                            htmlFor="cosigner-firstname2"
                                            className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            <span className="text-xs font-medium text-gray-700">First</span>

                                            <input
                                                required
                                                type="text"
                                                id="cosigner-firstname2"
                                                name="cosigner-firstname2"
                                                placeholder="John"
                                                className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>

                                        <label
                                            htmlFor="cosigner-lastname2"
                                            className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            <span className="text-xs font-medium text-gray-700">Last</span>

                                            <input
                                                required
                                                type="text"
                                                id="cosigner-lastname2"
                                                name="cosigner-lastname2"
                                                placeholder="Doe"
                                                className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>
                                    </div>

                                    <div className="input-field">
                                        <label
                                            htmlFor="cosigner-phone2"
                                            className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            <span className="text-xs font-medium text-gray-700">Phone</span>

                                            <input
                                                required
                                                type="text"
                                                id="cosigner-phone2"
                                                name="cosigner-phone2"
                                                placeholder="Phone"
                                                className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>
                                    </div>

                                    <div className="input-field">
                                        <label
                                            htmlFor="cosigner-email2"
                                            className="block w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            <span className="text-xs font-medium text-gray-700">Email</span>

                                            <input
                                                required
                                                type="email"
                                                id="cosigner-email2"
                                                name="cosigner-email2"
                                                placeholder="Email"
                                                className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>
                                    </div>
                                </div>
                            ) : null}

                        </>
                    ) : null}
                </div>

                <hr className="w-full h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />

                {/* Pet Info Section */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Pet Information</b>
                    </div>

                    <div className="checkbox-wrap">
                        <label htmlFor="pet">Do you have a pet/companion animal?</label>
                        <input type="checkbox" id="pet" name="pet" checked={togglePets} value={togglePets} onChange={handleTogglePets} />
                    </div>

                    {togglePets ? (
                        <div id="pet-options">
                            <br />
                            <div className="horizontal-input">
                                <label htmlFor="cat">Cat:</label>
                                <input type="checkbox" id="cat" name="cat" checked={hasCat} value={hasCat} onChange={handleHasCat} />
                            </div>

                            <div className="horizontal-input" id="cat-amount" style={!hasCat ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <label htmlFor="num-cats">Number of Cats: </label>
                                <input type="number" id="num-cats" name="num-cats" min="1" max="10" />
                            </div>

                            <br />

                            <div className="horizontal-input">
                                <label htmlFor="dog">Dog:</label>
                                <input type="checkbox" id="dog" name="dog" checked={hasDog} value={hasDog} onChange={handleHasDog} />
                            </div>

                            <div className="horizontal-input" id="dog-amount" style={!hasDog ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <label htmlFor="num-dogs">Number of Dogs: </label>
                                <input type="number" id="num-dogs" name="num-dogs" min="1" max="10" />
                            </div>

                            <br />

                            <div className="horizontal-input">
                                <label htmlFor="dog">Other:</label>
                                <input type="checkbox" id="other-pet" name="other-pet" checked={hasOther} value={hasOther} onChange={handleHasOther} />
                            </div>

                            <div className="vertical-input" id="other-amount" style={!hasOther ? { opacity: 0.35, pointerEvents: "none" } : { opacity: 1 }}>
                                <div className="horizontal-input">
                                    <label htmlFor="num-dogs">What kind of pet?</label>
                                    <input className="justify-text-right" type="text" id="about-other" name="about-other" placeholder="Pet Type" />
                                </div>
                                <div className="horizontal-input">
                                    <label htmlFor="num-other">Number: </label>
                                    <input type="number" id="num-other" name="num-other" min="1" max="10" />
                                </div>
                            </div>

                            <br />

                            <div className="vertical-input">
                                <label htmlFor="animal-weights">Please enter the weight of your pet(s):</label>
                                <textarea className="areas-of-interest-field" type="text" id="animal-weights" name="animal-weights"></textarea>
                            </div>

                            <br />
                        </div>
                    ) : null}
                </div>

                <hr className="w-full h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />

                {/* Rental Criterea & Preferences */}
                <div className="form-section">
                    <div className="section-header">
                        <b>Rental Preferences</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="rent-budget">Monthly Rent Budget:</label>
                        <input required className="justify-text-right" type="number" id="rent-budget" name="rent-budget" placeholder="$$$" min="0" />
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="num-beds">Number of Bedrooms:</label>
                        <input required className="justify-text-right" type="number" id="num-beds" name="num-beds" placeholder="1" min="1" max="5" step="1" />
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="num-beds">Number of Bathrooms:</label>
                        <input required className="justify-text-right" type="number" id="num-baths" name="num-baths" placeholder="1" min="1" max="5" step="1" />
                    </div>

                    <br />
                    {/* 
                    <div className="vertical-input">
                        <label htmlFor="areas-of-interest">Please list areas of interest (Fishtown, South Philadelphia, etc.):</label>
                        <textarea className="areas-of-interest-field" type="number" id="areas-of-interest" name="areas-of-interest"></textarea>
                    </div> */}

                    <label htmlFor="areas-of-interest" className="block mb-2 text-sm font-medium text-gray-900">Please list areas of interest (zip code list followed by commas)
                    </label>
                    <label htmlFor="zip-input" className="block mb-2 text-sm font-medium text-gray-900">
                        Example: "78734, 75087, 12523, #####"
                    </label>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                            </svg>
                        </div>
                        <input type="text" id="areas-of-interest" aria-describedby="helper-text-explanation" className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 y-400"
                            placeholder="19125, 19146, etc." required />
                    </div>
                    <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500">Please only select 5 digit numbers from 0 to 9.</p>

                    {/* <label for="zip-list" className="block mt-2 mb-2 text-sm font-medium text-gray-900">Zip codes:</label> */}


                    <br />

                    <div className="section-header">
                        <b>Additional Features</b>
                    </div>

                    <div className="horizontal-input">
                        <label htmlFor="washer-dryer">Washer/Dryer?</label>
                        <select name="washer-dryer" id="washer-dryer">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>


                    <div className="vertical-input">
                        <label htmlFor="other-preferences">Other preferences/requirements:</label>
                        <textarea className="areas-of-interest-field" type="text" id="other-preferences" name="other-preferences"></textarea>
                    </div>

                    {/* <div className="horizontal-input">
                        <label htmlFor="central-cooling">Central Cooling?</label>
                        <select name="central-cooling" id="central-cooling">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>

                    <br />

                    <div className="horizontal-input">
                        <label htmlFor="off-street">Off Street Parking?</label>
                        <select name="off-street" id="off-street">
                            <option></option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div> */}
                </div>

                <hr className="w-full h-1 mx-auto my-0 bg-stone-300 border-0 rounded md:my-4" />

                <div className="form-section">
                    <div className="terms-wrap">
                        <input required type="checkbox" id="agree-to-terms" name="agree-to-terms"
                            checked={toggleAgree} value={toggleAgree} onChange={handleToggleAgree}
                        />
                        <label htmlFor="agree">
                            <b>
                                I agree to be contacted by Mishael Scott via call, email, and text. To opt out, you can reply
                                'stop' at any time or click the unsubscribe link in the emails. Message and data rates may
                                apply. Msg frequency varies.
                            </b>
                            {/* <br /> */}
                            <p className="text-blue-700 underline mt-6 cursor-pointer" onClick={handleTOSClick}>Privacy Policy & Terms of Service</p>
                        </label>
                    </div>
                </div>

                <div className="form-section end-form-gap">
                    <button className="submit-button" type="submit" id="submit-button">Done</button>
                </div>

                {/* <div id="confirmationModal">
                    <div className="modal-content">
                        <span className="close-button">&times;</span>
                        <p>Form submitted successfully!</p>
                    </div>
                </div> */}
            </form >
        </motion.div >
    );
}

export default Form;