import * as React from "react";
import './Feedback.css';
import { useState } from 'react';
import logoSVG from "../../assets/logosvg.svg"
import { /*useLocation,*/ useNavigate } from 'react-router-dom';

function Feedback(props) {
    const navigate = useNavigate();
    const [ratingVal, setRatingVal] = useState(0);
    const [ratingValArray, setRatingValArray] = useState(["bg-gray-400", "bg-gray-400", "bg-gray-400", "bg-gray-400", "bg-gray-400"]);

    const handleHomeClick = (e) => {
        navigate("/home");
    }

    const handleValArray = (value) => {
        console.log(value);

        setRatingVal(value);
        var tempArr = ["", "", "", "", ""];

        for (let i = 0; i < value; i++) {
            tempArr[i] = "bg-black";
        }

        for (let i = value; i <= 4; i++) {
            tempArr[i] = "bg-gray-400";
        }

        console.log(tempArr);

        setRatingValArray(tempArr);
    }

    const handleSubmit = event => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/feedback", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
            .then(() => console.log("Feedback Form successfully submitted"))
            .catch(error => alert(error));

        navigate('/thankyou');
    };

    return (
        <div className="feedback-wrap">
            <div className="feedback-header">
                <img className="adin-logo" src={logoSVG} alt="adinqra-logo" onClick={handleHomeClick} />
            </div>
            <form
                name="feedback"
                id="feedback"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="feedback-form"
                onSubmit={handleSubmit}
                action="/thankyou"
            >
                <input type="hidden" name="form-name" value="feedback" />

                <p className="font-bold text-2xl mb-2">Submission successful</p>
                {/* <p className="bold text-lg mb-2">Thanks for your submission!</p> */}
                <p className="mb-6">We are always looking to improve, any feedback is greatly appreciated. </p>

                <p className="font-bold mb-2">Rating 1-5:</p>
                <div className="flex flex-row-reverse justify-center">
                    <div
                        onClick={() => { handleValArray(5) }}
                        className={"rounded-full peer peer-hover:bg-black hover:bg-black w-12 h-12 mx-1 " + ratingValArray[4]}>
                        <p className="mt-3"></p>
                    </div>
                    <div
                        onClick={() => { handleValArray(4) }}
                        className={"rounded-full peer peer-hover:bg-black hover:bg-black w-12 h-12 mx-1 " + ratingValArray[3]}>
                        <p className="mt-3"></p>
                    </div>
                    <div
                        onClick={() => { handleValArray(3) }}
                        className={"rounded-full peer peer-hover:bg-black hover:bg-black w-12 h-12 mx-1 " + ratingValArray[2]}>
                        <p className="mt-3"></p>
                    </div>
                    <div
                        onClick={() => { handleValArray(2) }}
                        className={"rounded-full peer peer-hover:bg-black hover:bg-black w-12 h-12 mx-1 " + ratingValArray[1]}>
                        <p className="mt-3"></p>
                    </div>
                    <div
                        onClick={() => { handleValArray(1) }}
                        className={"rounded-full peer peer-hover:bg-black hover:bg-black w-12 h-12 mx-1 " + ratingValArray[0]}>
                        <p className="mt-3"></p>
                    </div>
                </div>

                <input type="hidden" name="rating" value={ratingVal} />

                <label for="feedback-message" className="font-bold mt-6 mb-2">How can we improve?</label>

                <textarea id="feedback-message" name="feedback-message" rows="4"
                    className="block mx-auto p-2.5 w-4/5 text-sm text-gray-900 bg-white-50 rounded-lg border border-gray-300 focus:ring-red-100 focus:border-red-100 "
                    placeholder="Write your thoughts here..."
                ></textarea>

                <button className="feedback-btn mx-auto mt-4"
                    type="submit"
                    id="feedback-submit"
                >
                    Submit
                </button>
            </form>
        </div>
    );
}

export default Feedback;