import React from 'react';
import "./AboutUs.css";
import logoSVG from "../../assets/logosvg.svg";
import { motion } from "framer-motion";
import { useState } from 'react';

function AboutUs() {
    const [contactSubmit, setContactSubmit] = useState(false);

    const handleTOSClick = (e) => {
        var win = window.open("/tos", '_blank');
        win.focus();
        // navigate("/tos");
    }

    const handleSubmit = event => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/about", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
            .then(() => console.log("Contact Adinqra Form successfully submitted"))
            .catch(error => alert(error));

        setContactSubmit(true);
    };

    return (
        <React.Fragment
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                id="contact-section" className='contact-us-wrapper pt-20 pb-7 flex flex-row justify-center flex-wrap-reverse gap-7'
            >
                <div className='contact-info m- p-7 my-auto flex flex-col justify-center text-center text-black'>
                    <div className=''>
                        <div className="font-medium">Adinqra</div>
                        <div>Mishael Scott</div>
                        <div>(267) 641-4122</div>
                        <div className='underline cursor-pointer'>inquire@adinqra.co</div>
                    </div>

                    <hr className="w-full h-1 my-6 bg-black border-0 rounded my-0 opacity-40" />

                    <div className=''>
                        <div className="font-medium">Key Legacy Realty</div>
                        <div>(245) 778-9352</div>
                        <div>100 N 18th St, #300</div>
                    </div>

                    <a className="about-us-button" href="#about-us-info">about us</a>
                </div>

                <div className='contact-info-wrap flex flex-col justify-between gap-3'>
                    <div className='flex flex-row justify-left'>
                        {/* <label className='mt-auto font-medium redden'>Get In Touch</label> */}
                        <img className="w-8" src={logoSVG} alt="adinqra-logo" />
                        {/* <hr className="w-full mx-2 my-auto h-1 bg-black border-1 my-0 opacity-100" /> */}
                    </div>

                    <form
                        name="contact-adinqra"
                        id="contact-adinqra"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        className="flex flex-col gap-2"
                        onSubmit={handleSubmit}
                        action="/about"
                    >
                        <input type="hidden" name="form-name" value="contact-adinqra" />

                        {
                            contactSubmit ?
                                (
                                    <div className=''>
                                        Thanks for getting in touch, <b>Adinqra</b> will reach out soon!
                                    </div>
                                ) :
                                (
                                    <React.Fragment>
                                        <div className="flex flex-row gap-2">
                                            <label
                                                htmlFor="contact-firstname"
                                                className="block bg-gray-100 w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                            >
                                                {/* <span className="text-xs font-medium text-gray-700">First</span> */}

                                                <input
                                                    required
                                                    type="text"
                                                    id="contact-firstname"
                                                    name="contact-firstname"
                                                    placeholder="First"
                                                    className="mt-1 bg-gray-100 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                                />
                                            </label>

                                            <label
                                                htmlFor="contact-lastname"
                                                className="block bg-gray-100 w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                            >
                                                {/* <span className="text-xs font-medium text-gray-700">Last</span> */}

                                                <input
                                                    required
                                                    type="text"
                                                    id="contact-lastname"
                                                    name="contact-lastname"
                                                    placeholder="Last"
                                                    className="mt-1 bg-gray-100 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                                />
                                            </label>
                                        </div>

                                        <label
                                            htmlFor="contact-email"
                                            className="block bg-gray-100 w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            {/* <span className="text-xs font-medium text-gray-700">Email</span> */}

                                            <input
                                                required
                                                type="email"
                                                id="contact-email"
                                                name="contact-email"
                                                placeholder="Email"
                                                className="mt-1 bg-gray-100 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>

                                        <label
                                            htmlFor="contact-phone"
                                            className="block bg-gray-100 w-full overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-200 focus-within:ring-1 focus-within:ring-gray-200"
                                        >
                                            {/* <span className="text-xs font-medium text-gray-700">Phone</span> */}

                                            <input
                                                required
                                                type="text"
                                                id="contact-phone"
                                                name="contact-phone"
                                                placeholder="Phone"
                                                className="mt-1 bg-gray-100 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                                            />
                                        </label>

                                        <div className="terms-wrap m-2">
                                            <input required type="checkbox" id="agree-to-terms" name="agree-to-terms"
                                            />
                                            <label htmlFor="agree">
                                                <p className='font-medium text-xs'>
                                                    I agree to be contacted by Mishael Scott via call, email, and text. To opt out, you can reply
                                                    'stop' at any time or click the unsubscribe link in the emails. Message and data rates may
                                                    apply. Msg frequency varies.
                                                </p>
                                                {/* <br /> */}
                                                <p className="text-blue-700 text-xs underline mt-0 cursor-pointer" onClick={handleTOSClick} >Privacy Policy & Terms of Service</p>
                                            </label>
                                        </div>

                                        <button className='contact-submit mt-2' type='submit'>Submit</button>
                                    </React.Fragment>
                                )
                        }
                    </form>
                    {/* <div className='flex flex-col gap-2'>

                    </div> */}
                    {/* <input className='contact-input' type='text' placeholder='First Name'></input>
                    <input className='contact-input' type='text' placeholder='Last Name'></input>
                    <input className='contact-input' type='text' placeholder='Email'></input>
                    <input className='contact-input' type='text' placeholder='Phone #'></input>
                    <button className='contact-us-button' type='submit'>Submit</button> */}
                </div>

                {/* <div className='bottom-close'>
                    <button className='close-button text-gray-200'>
                        close X
                    </button>
                </div> */}


            </motion.div>

            <motion.div className='about-us-wrapper'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                id="about-us-info"
            >

                <div className='about-us-inner-wrap'>
                    <div className='copywrite-text-wrap'>
                        <div className='text-4xl mb-2 mt-7 flex justify-center'>
                            <img className="w-10" src={logoSVG} alt="adinqra-logo" />
                            {/* <h1 className='redden ml-3'>Adinqra</h1> */}
                        </div>

                        {/* <hr className="w-full h-1 bg-red-300 opacity-40" /> */}
                        <div className='copywrite-sec gap-5 flex flex-col justify-between'>


                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.1 }}
                                className=''>

                                {/* <div className='m-auto text-md font-medium mb'>About Us</div> */}

                                <div className=''>
                                    <span className='redden font-bold'>
                                        Adinqra
                                    </span>
                                    {" "}is a forward-thinking tech company overhauling the real estate industry by
                                    <span className='redden'>
                                        {" "}redefining workflows, enhancing efficiency,
                                    </span>
                                    {" "}and
                                    <span className='redden'>
                                        {" "}delivering client-focused solutions.
                                    </span>
                                    {" "}Our innovative platform redefines how real estate professionals
                                    <span className='redden'>
                                        {" "}manage transactions, collaborate with clients
                                    </span>
                                    , and access
                                    <span className='redden'>
                                        {" "}essential tools
                                    </span>
                                    {" "}- all in one ecosystem.
                                </div>
                            </motion.div>

                            {/* <hr className="w-full h-1 bg-red-300 border-0 rounded my-0 opacity-40" /> */}

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                                className=''>
                                {/* <div className='m-auto text-md font-medium mb'>Our Mission</div> */}
                                At the heart of our mission is a commitment to simplifying complex processes and empowering
                                agents, brokers, and stakeholders to achieve greater productivity and success.
                                By leveraging cutting-edge technologies like artificial intelligence, data analytics, and
                                cloud integration, we create a smarter, more transparent approach to real estate operations.

                            </motion.div>

                            {/* <hr className="w-full h-1 bg-red-300 border-0 rounded my-0 opacity-40" /> */}

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className=''>

                                {/* <div className='m-auto text-md font-medium mb'>Our Approach</div> */}

                                Our client-first hierarchy ensures that every product and service we design is tailored to meet
                                the evolving needs of our users.  We value
                                <span className='redden'>
                                    {" "}collaboration, adaptability,
                                </span>
                                {" "}and
                                <span className='redden'>
                                    {" "}innovation
                                </span>
                                , striving to set new standards for how the real estate industry operates in a
                                <span className='redden'>
                                    {" "}tech-driven
                                </span>
                                {" "} world.
                            </motion.div>
                        </div>
                        <a className="contact-us-button" href="#contact-section">contact</a>
                    </div>

                    {/* <div className='logo-wrap flex flex-col justify-center'>
                        <img className="logo-about" src={logoSVG} alt="adinqra-logo" />
                    </div> */}
                </div>

            </motion.div>
        </React.Fragment >
    )
}

export default AboutUs
